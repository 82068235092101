<template>
  <base-section id="products">
    <base-section-heading title="Products"> </base-section-heading>

    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="padding: 36px"
        >
          <v-row :justify="screenWidth < 600 ? 'center' : 'end'">
          </v-row>
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="margin-bottom: 36px; padding: 16px"
        >
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="padding: 16px"
        >
          
        </v-col>
        <v-col
          cols="12"
          xl="3"
          lg="3"
          md="6"
          sm="6"
          xs="6"
          style="margin-bottom: 36px; padding: 36px"
        >
          <v-row :justify="screenWidth < 600 ? 'center' : 'start'">
           
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionProducts",

  data: () => ({
    features: [
      {
        title: "Commission-free sales & booking management",
        icon: "mdi-receipt",
        description:
          "Our platform supports both standard and timed orders while having no extra charges applied."
      },
      {
        title: "Powerful customization",
        icon: "mdi-fountain-pen-tip",
        description:
          "We aspire to support any need a small-time business owner might require. Our phone apps and online forms support versatile customization opportunities for almost any case."
      },
      {
        title: "Diverse sale objects",
        icon: "mdi-shopping-outline",
        description:
          "TENTIFLY supports list and graphical sale for goods such as products, services & rental items. All these can be sold & bundled together with our bundle configurator."
      },
      {
        title: "GDPR compliance",
        icon: "mdi-gavel",
        description:
          "Solutions are designed to be fully compliant with GDPR personal data processing rules."
      },
      {
        title: "Smart processes",
        icon: "mdi-school-outline",
        description:
          "Streamlined business is in what we believe in. Our solutions are designed to speed up and save on time wasted managing. With TENTIFLY you focus on what matters!"
      },
      {
        title: "Swift and easy",
        icon: "mdi-bike-fast",
        description:
          "TENTIFLY is designed to be set-up quickly and implements easy to understand management principles. Our core is designed to allow you to decentralize management and engage employees directly at work posts!"
      },
      {
        title: "No-setup TENTIFLY store webpage",
        icon: "mdi-store-outline",
        description:
          "All our subscriptions have out-of-box configured web storefront at our TENTIFLY online marketplace. We support both private and marketplace store views."
      },
      {
        title: "Multi-platform & plugin support",
        icon: "mdi-monitor-cellphone-star",
        description:
          "TENTIFLY comes with web and mobile apps which can be configured for each form separately. With your own webpage TENTIFLY provides plugin for professional addon. Any changes in TENTIFLY management portal will update content in apps automatically."
      },
      {
        title:
          "Employees, automatic schedules (soon available!) & motivation system",
        icon: "mdi-calendar-check-outline",
        description:
          "TENTIFLY promotes employee self-engagement. Assign or let employees assign themselves, get quick overview on order task-based statuses and receive overview on their performance."
      },
      {
        title: "FREE marketing tools (soon available!)",
        icon: "mdi-cart-arrow-up",
        description:
          "With each TENTIFLY subscription we provide free and alternatively low-cost marketing solutions and statistics. Moreover, TENTIFLY is about to launch our unique community-powered advertising addon to boost reach even further. Stay tuned!"
      }
    ]
  })
};
</script>
